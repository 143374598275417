<template>
  <div id="info-page">
    <v-flex xs12>
      <v-layout wrap class="info-page">
        <div> 
          <h1 v-if="!isMobile"> {{ $t('personal-info.title')}}</h1>
          
          <div class="item"> 
            <p class="text">
              {{ $t('personal-info.subtitle')}}
            </p>
          </div>
        </div>

        <v-flex xs12 sm12 md12>
          <v-form
            ref="form"
            class="mt-9"
            v-model="valid"
            lazy-validation
            @submit="submit()"
          >
            <v-layout wrap>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-model="name"
                  ref="name"
                  :label="$t('personal-info.name')"
                  :rules="nameRules"
                  :outlined="isMobile"
                  :maxlength="60"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5 offset-md1>
                <v-combobox
                  v-model="gender"
                  :items="['MALE', 'FEMALE']"
                  :label="$t('register.gender')"
                  :outlined="isMobile"
                  :rules="genderRules"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="birthDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthDate"
                      :outlined="isMobile"
                      :label="$t('register.birth-date')"
                      append-icon="mdi-calendar"
                      :rules="birthDateRules"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="birthDate"
                    :max="maxDate"
                    :min="minDate"
                    no-title
                    scrollable
                    @input="$refs.menu.save(birthDate)"
                  >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs12 sm12 md5 offset-md1>
                <v-combobox
                  v-model="profesion"
                  :items="jobOptions"
                  item-text="name"
                  item-value="id"
                  :label="$t('register.profesion')"
                  :outlined="isMobile"
                  :rules="jobRules"
                ></v-combobox>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-model="email"
                  ref="email"
                  label="Email"
                  :outlined="isMobile"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5 offset-md1>
                <v-text-field
                  v-model="phone"
                  ref="phone"
                  :label="$t('personal-info.phone')"
                  :outlined="isMobile"
                  disabled
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md5>
                <v-text-field
                  v-model="instagram"
                  ref="instagram"
                  label="Instagram"
                  :rules="igRules"
                  :outlined="isMobile"
                  :maxlength="30"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-flex xs12 sm12 md5 class="mb-9">
              <v-btn class="mt-5" tile color="black" dark width="100%" height="44px" @click="submit()">
                <span class="btn-text"> {{ $t('personal-info.edit')}} </span>
              </v-btn>
            </v-flex>
          </v-form>
         </v-flex>
      </v-layout>
    </v-flex>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import axios from '@/axios'
   import moment from 'moment'

  export default {
    name: 'PersonalInfo',
     computed: {
      ...mapState([
        'isMobile',
        'token'
      ]),
      maxDate () {
        let date = new Date()
        let year = moment(date).format('YYYY')
        let maxYear = (Number(year) - 15)
        let maxDate = maxYear+'-12-31'

        return maxDate
      },
      minDate () {
        return '1933-01-01'
      }
    },
    data () {
      return {
        menu: false,
        name:'',
        email: '',
        instagram: '',
        phone: '',
        gender: '',
        birthDate: '',
        profesion: '',
        valid: true,
        jobOptions: [
          { id: 'KARYAWAN', name: 'Karyawan'},
          { id: 'PELAJAR', name: 'Pelajar / Mahasiswa'},
          { id: 'WIRAUSAHA', name: 'Wiraswasta / Wirausaha'}
        ],
        nameRules: [
          v => !!v || this.$t('personal-info.error-name'),
        ],
        jobRules: [
          v => !!v || this.$t('register.form.error-profesion'),
          // v => v && v.length > 0 || this.$t('register.form.error-profesion')
        ],
        genderRules: [
          v => !!v || this.$t('register.form.error-gender'),
        ],
        birthDateRules: [
          v => !!v || this.$t('register.form.error-birthdate'),
          v => this.meetsMinimumAge(v,15) || this.$t('register.form.error-birthdate-min')
        ],
        igRules: [
          v => !!v || this.$t('register.form.error-ig'),
        ]
      }
    },
    methods: {
      initForm () {
        this.$store.commit('setIsLoading', true)

        axios.defaults.headers = {
          id_brand: process.env.VUE_APP_BRAND,
          token: this.token
        }

        axios.get('/api/v1/member/profile')
          .then(response => {
            this.$store.commit('setPersonalData', response.data.data)
            this.name = response.data.data.nama
            this.email = response.data.data.email
            this.phone = response.data.data.hp
            this.instagram = response.data.data.instagram
            this.gender = response.data.data.gender
            // this.profesion = response.data.data.jenis_pekerjaan.map(item => {
            //   return {
            //     id: item,
            //     name: this.jobOptions.find(option => option.id === item).name
            //   }
            // })
            this.profesion = 
              response.data.data.jenis_pekerjaan && Array.isArray(response.data.data.jenis_pekerjaan) 
              ? {
                  id: response.data.data.jenis_pekerjaan[0],
                  name: this.jobOptions.find(option => option.id === response.data.data.jenis_pekerjaan[0]).name
              } : ''
            this.birthDate = response.data.data.tanggal_lahir
          })
          .catch(error => {
            this.errorHandling(error)
          })
          .finally(() => {
            this.$store.commit('setIsLoading', false)
          })
      },
      submit () {
        if (this.$refs.form.validate()) {
          this.$store.commit('setIsLoading', true)

          axios.defaults.headers = {
            id_brand: process.env.VUE_APP_BRAND,
            token: this.token
          }

          axios
            .patch('/api/v1/member/profile', {
              nama: this.name,
              instagram: this.instagram,
              gender: this.gender,
              // jenis_pekerjaan: this.profesion.map(item => item.id),
              jenis_pekerjaan: [this.profesion.id],
              tanggal_lahir: this.birthDate
            })
            .then(response => {
              this.$store.commit('setSnackbar', true)
              this.$store.commit('setText', response.data.message)
              this.initForm();

              setTimeout(() => {
                if (this.$route.query && this.$route.query.redirectTo) {
                  this.$router.push({name: this.$route.query.redirectTo})
                }
              }, 500)
            })
            .catch(error => {
              this.errorHandling(error)
            })
            .finally(() => {
              this.$store.commit('setIsLoading', false)
            })
        }
      }
    }, 
    mounted () {
      this.initForm()

      this.setMetaInfo(null, 'Personal Info')

      this.$store.commit('setIsHome', false)
      this.$store.commit('setIsLoginPage', false)
      this.$store.commit('setIsMemberPage', true)
      this.$store.commit('setActivePage', this.$t('personal-info.title'))
    }
  }
</script>

<style scoped>
  .text {
    color: #000000;
    margin-bottom: 5px;
    font-size: 0.9rem !important;
  }
  .btn-text {
    text-transform: uppercase;
  }
</style>
